import React, { KeyboardEventHandler, ReactNode } from 'react';
import { TextInput } from 'src/components/common/form/input';
import SearchIcon from '@heroicons/react/solid/SearchIcon';

type TProps = {
    title: string;
    onChange?: (value: string) => void;
    children?: ReactNode;
    onKeyPress?: KeyboardEventHandler | undefined;
};

const PageHeader = ({ title, children, onKeyPress }: TProps) => {
    return (
        <div className="flex pb-7.5 sm:pb-4 flex-col sm:flex-row">
            <h1 className="text-[26px] font-bold text-gray-900 whitespace-nowrap pb-4 sm:pb-0 leading-8">
                {title}
            </h1>
            {children}
            {onKeyPress && (
                <div className="sm:ml-auto pt-2 sm:pt-0">
                    <TextInput
                        id="search"
                        padding="px-3 py-2"
                        placeholder="Enter..."
                        className="placeholder-black w-full md:w-auto pl-7.5"
                        icon={SearchIcon}
                        onKeyPress={onKeyPress}
                    />
                </div>
            )}
        </div>
    );
};

export default PageHeader;
